<template>
  <div class="container">
    <h2 class="text-center my-4 ">
      <strong> PROMOCIONES Y OFERTAS ECART.COM </strong>
    </h2>

    <b-tabs content-class="mt-3" v-model="tabIndex">
      <b-tab title="ENVÍOS NACIONALES">
        <div>
          <div class="text-right">
            <p><strong>FECHA DE ACTUALIZACI&Oacute;N Y PUBLICACI&Oacute;N:</strong></p>
            <p><strong>Monterrey, Nuevo Le&oacute;n, 10 de agosto del 2020.</strong></p>
          </div>

          <p class="text-center f-16"><strong>&ldquo;ENV&Iacute;OS NACIONALES 2020&rdquo;</strong></p>

          <p><span style="font-weight: 400;">Los siguientes t&eacute;rminos y condiciones regir&aacute;n la
              PROMOCI&Oacute;N de &ldquo;ENV&Iacute;OS NACIONALES&rdquo; que ECART TECHNOLOGIES S.A. DE C.V. (en
              adelante &uml;ecart&uml;) ofrecer&aacute;n a los usuarios que deseen utilizar la plataforma digital de
            </span><a href="http://www.ecart.com" target="_blank"><span
                style="font-weight: 400;">www.ecart.com</span></a><span style="font-weight: 400;">.</span></p>
          <p><strong>Ecart Technologies S.A. de C.V.</strong><span style="font-weight: 400;">, tiene su domicilio
              ubicado en </span><strong>Belisario Dom&iacute;nguez n&uacute;mero 2470, piso 3, Colonia Obispado,
              Monterrey, Nuevo Le&oacute;n, C.P. 64060.</strong></p>
          <p><span style="font-weight: 400;">AL USAR EL SITIO WEB, USTED ACEPTA Y EST&Aacute; DE ACUERDO CON LOS
              T&Eacute;RMINOS Y CONDICIONES DE PROMOCIONES Y OFERTAS EN LO QUE SE REFIERE A SU USO DEL SITIO WEB. Si no
              estas conforme con estos t&eacute;rminos y condiciones, por favor no hagas uso del sitio web de </span><a
              href="https://envia.com/es-MX/www.envia.com" target="_blank"><span
                style="font-weight: 400;">ecart.com</span></a><span style="font-weight: 400;">. Si tienes alguna duda
              respecto a los t&eacute;rminos y condiciones, por favor
              escribe un correo electr&oacute;nico a&nbsp;</span><a href="mailto:clientes@envia.com"><span
                style="font-weight: 400;">support@ecart.com</span></a><span style="font-weight: 400;">.</span></p>
          <p class="text-center f-16"><strong>CONCEPTOS APLICABLES</strong></p>
          <ol class="decimal">
            <li style="font-weight: 400;"><strong>Seller/Usuario:</strong><span style="font-weight: 400;"> Es la persona
                que se ha registrado en la plataforma de ecart.com para distribuir, comercializar y vender los
                art&iacute;culos dentro de la plataforma, con el beneficio de que cualquier persona pueda visualizar sus
                art&iacute;culos y adquirirlos f&aacute;cilmente.&nbsp;</span></li>
            <li style="font-weight: 400;"><strong>Cliente:</strong><span style="font-weight: 400;"> Es la persona que
                entra a la plataforma de ecart.com con la finalidad de adquirir y/o comprar en l&iacute;nea a las
                tiendas disponibles en dicha plataforma cualquier art&iacute;culo que sea de su agrado y/o
                inter&eacute;s.</span></li>
          </ol>
          <p class="text-center f-16"><strong>ESPECIFICACI&Oacute;N DE PROMOCI&Oacute;N &ldquo;ENV&Iacute;OS NACIONALES
              2020&rdquo;:</strong></p>
          <p><span style="font-weight: 400;">ECART otorga a los Seller registrados en Ecart para la venta y
              comercializaci&oacute;n de sus art&iacute;culos, productos y/o mercanc&iacute;as 20 (veinte) gu&iacute;as
              nacionales disponibles y gratuitas por tienda a partir del 01 (uno) de agosto del 2020 (dos mil veinte) en
              el entendido que, al generar la gu&iacute;a n&uacute;mero 21 (veintiuno) se cobrar&aacute; el env&iacute;o
              al destino estipulado en la solicitud de compra por el cliente en la plataforma de ecart.com&nbsp;</span>
          </p>
          <p class="text-center f-16 text-underline "><strong>La presente oferta se regir&aacute; bajo las siguientes
              especificaciones:</strong></p>

          <ol class="decimal">
            <li style="font-weight: 400;"><strong>PROMOCI&Oacute;N ENV&Iacute;OS NACIONALES 2020.</strong><span
                style="font-weight: 400;"> Candidatos para adquirir los beneficios de promoci&oacute;n y oferta:</span>
              <ol>
                <li style="font-weight: 400;"><span style="font-weight: 400;">El Seller que cuente con tiendas
                    registradas
                    para la comercializaci&oacute;n y venta de sus productos y/o mercanc&iacute;as en la plataforma de
                    Ecart.</span></li>
              </ol>
              <br>
            </li>

            <li style="font-weight: 400;"><strong>LINEAMIENTOS ENV&Iacute;OS NACIONALES 2020.</strong><span
                style="font-weight: 400;"> El Seller obtendr&aacute; los beneficios adquiridos en la oferta
                &uml;ENV&Iacute;OS NACIONALES 2020&uml; conforme los siguientes lineamientos:</span>
              <ol>
                <li style="font-weight: 400;"><span style="font-weight: 400;">La promoci&oacute;n de gu&iacute;as
                    nacionales
                    gratuitas solo es aplicable para las primeras 20 (veinte) gu&iacute;as nacionales, a partir de la
                    gu&iacute;a n&uacute;mero 21 (veintiuno) se cobrar&aacute; la gu&iacute;a de acuerdo con el punto de
                    destino estipulado en la solicitud de compra del cliente, en el entendido que, el precio
                    podr&aacute;
                    variar seg&uacute;n el destino al que el cliente desee enviar el producto adquirido.</span></li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">La promoci&oacute;n ser&aacute; aplicable
                    para
                    el cliente que adquiera productos de un valor m&iacute;nimo de $449.00 (cuatrocientos cuarenta y
                    nueve
                    pesos 00/100 MXN). En el entendido que podr&aacute; convertirse dicho monto a las siguientes
                    divisas:
                    d&oacute;lares, euros y pesos colombianos.</span></li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">La promoci&oacute;n aplica
                    &uacute;nicamente
                    para destinos dentro del &aacute;rea de la Rep&uacute;blica Mexicana.</span></li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">La promoci&oacute;n ser&aacute;
                    &uacute;nicamente para pedidos no mayores a 2 (dos) kilogramos volum&eacute;tricos</span> <span
                    style="font-weight: 400;">a trav&eacute;s siguientes paqueter&iacute;as: Sendex, Quiken y
                    Redpack.</span></li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">La promoci&oacute;n no ser&aacute;
                    transferible.</span></li>
                <li style="font-weight: 400;"><span style="font-weight: 400;">La promoci&oacute;n no ser&aacute;
                    acumulable
                    con alguna otra.</span></li>
              </ol>
              <br>
            </li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">&nbsp;</span><strong>VALIDEZ DE LA
                PROMOCI&Oacute;N. </strong><span style="font-weight: 400;">El procedimiento para hacer v&aacute;lida la
                promoci&oacute;n de &ldquo;ENV&Iacute;OS NACIONALES 2020&rdquo; es el siguiente:</span>
              <ol>
                <li style="font-weight: 400;"><span style="font-weight: 400;">Al momento de generar la gu&iacute;a e
                    ingresar la direcci&oacute;n nacional de env&iacute;o, se le descontar&aacute; el costo con
                    referencia
                    al precio de dicha gu&iacute;a, &uacute;nicamente para las primeras 20 (veinte) gu&iacute;as
                    nacionales,
                    en el entendido que al realizar la gu&iacute;a n&uacute;mero 21 (veintiuno) se proceder&aacute; al
                    cobro
                    de la gu&iacute;a dentro de la plataforma de ecart.com.</span></li>
              </ol>
            </li>
          </ol>

          <p><span style="font-weight: 400;">Al participar en la promoci&oacute;n </span><span
              style="font-weight: 400;">&ldquo;</span><strong>ENV&Iacute;OS NACIONALES 2020&rdquo;</strong><span
              style="font-weight: 400;">, el Seller afirma que ha le&iacute;do y comprendido los presentes
              T&eacute;rminos y Condiciones, aceptando la veracidad, legitimaci&oacute;n y lineamientos por los cuales
              se regir&aacute; la promoci&oacute;n de manera libre y consciente, habiendo de su parte revisado
              satisfactoriamente la informaci&oacute;n descrita anteriormente, la cual se considera adecuada y
              suficiente.</span></p>

        </div>
      </b-tab>

      <b-tab title="ENVÍOS INTERNACIONALES">
        <div>
          <p class="text-right"><strong>FECHA DE PUBLICACI&Oacute;N:</strong></p>
          <p class="text-right"><strong>Monterrey, Nuevo Le&oacute;n, 01 de junio del 2020.</strong></p>
          <p class="text-center f-16"><strong>&ldquo;ENV&Iacute;OS INTERNACIONALES&rdquo;</strong></p>
          <p><span style="font-weight: 400;">Los siguientes t&eacute;rminos y condiciones regir&aacute;n la
              PROMOCI&Oacute;N
              de </span><strong>&ldquo;ENV&Iacute;OS INTERNACIONALES&rdquo; </strong><span style="font-weight: 400;">que
              ECART TECHNOLOGIES S.A. DE C.V. (en adelante &u ml;ecart&uml;) ofrecer&aacute;n a los usuarios que deseen
              utilizar la plataforma digital de </span><a href="http://ecart.com" target="_BLANK"><span
                style="font-weight: 400;">ecart.com</span></a><span style="font-weight: 400;">.</span></p>
          <p><strong>Ecart Technologies S.A. de C.V.</strong><span style="font-weight: 400;">, tiene su domicilio
              ubicado en
            </span><strong>Belisario Dom&iacute;nguez n&uacute;mero 2470, piso 3, Colonia Obispado, Monterrey, Nuevo
              Le&oacute;n, C.P. 64060.</strong></p>
          <p><span style="font-weight: 400;">AL USAR EL SITIO WEB, USTED ACEPTA Y EST&Aacute; DE ACUERDO CON LOS
              T&Eacute;RMINOS Y CONDICIONES DE PROMOCIONES Y OFERTAS EN LO QUE SE REFIERE A SU USO DEL SITIO WEB. Si no
              estas conforme con estos t&eacute;rminos y condiciones, por favor no hagas uso del sitio web de </span><a
              href="http://www.ecart.com" target="_BLANK"><span style="font-weight: 400;">ecart.com</span></a><span
              style="font-weight: 400;">. Si tienes alguna duda respecto a los t&eacute;rminos y condiciones, por favor
              escribe un correo electr&oacute;nico a&nbsp;</span><a href="mailto:support@ecart.com"><span
                style="font-weight: 400;">support@ecart.com</span></a><span style="font-weight: 400;">.</span></p>
          <p class="text-center f-16"><strong>CONCEPTOS APLICABLES</strong></p>
          <ol class="decimal">
            <li><strong>Cliente:<span style="font-weight: 400;"> Es la persona que entra a la plataforma de ecart.com
                  con la
                  finalidad de adquirir y/o comprar productos en l&iacute;nea.</span></strong></li>
            <li><strong>Env&iacute;os Internacionales: <span style="font-weight: 400;">Ser&aacute;n los productos
                  enviados a
                  cualquier pa&iacute;s a excepci&oacute;n de la Rep&uacute;blica Mexicana.</span></strong></li>
          </ol>
          <br>
          <p class="text-center f-16"><strong>ESPECIFICACI&Oacute;N DE PROMOCI&Oacute;N &ldquo;ENV&Iacute;OS
              INTERNACIONALES&rdquo;:</strong></p>
          <p><span style="font-weight: 400;">Ecart ofrece a sus clientes una tarifa preferencial de $10.99 USD* por cada
              mercanc&iacute;a y/o producto adquirido dentro de la plataforma ecart.com, en el cual el env&iacute;o
              ser&aacute; con destino a cualquier pa&iacute;s a excepci&oacute;n de la Rep&uacute;blica
              Mexicana.&nbsp;</span></p>
          <p class="text-center f-16 text-underline"><strong>La presente oferta se regir&aacute; bajo las siguientes
              especificaciones:</strong></p>
          <ol class="decimal">
            <li style="font-weight: 400;"><strong>PROMOCI&Oacute;N &ldquo;ENV&Iacute;OS INTERNACIONALES&rdquo;.
              </strong><span style="font-weight: 400;">Candidatos para adquirir los beneficios de promoci&oacute;n y
                oferta:</span>
              <br>
              <ol>
                <li><strong><span style="font-weight: 400;">La mercanc&iacute;a deber&aacute; tener un peso menor a 2
                      (dos)
                      kilogramos.&nbsp;</span></strong></li>

                <li><span style="font-weight: 400;">El Cliente registrado en la plataforma de ecart.com que realice una
                    compra y
                    el destino sea distinto a M&eacute;xico, es decir, un env&iacute;o internacional.</span></li>
              </ol>
              <br>
              <p class="ml-3"><span style="font-weight: 400;">No son candidatos para disfrutar la
                  promoci&oacute;n:&nbsp;</span></p>
              <ol>
                <li><span style="font-weight: 400;"> Las mercanc&iacute;as y/o productos adquiridos con
                    destino a la Rep&uacute;blica Mexicana.</span></li>
                <li><span style="font-weight: 400;"> Las mercanc&iacute;as y/o productos que tengan un
                    peso
                    mayor a 2 (dos) kilogramos.</span></li>
                <li> <span style="font-weight: 400;"> La promoci&oacute;n no ser&aacute;
                    transferible.</span>
                </li>
                <li><span style="font-weight: 400;">La promoci&oacute;n no ser&aacute; acumulable con
                    alguna
                    otra.</span></li>
              </ol>

            </li>
            <li style="font-weight: 400;"><span style="font-weight: 400;">&nbsp;</span><strong>VALIDEZ DE LA
                PROMOCI&Oacute;N. </strong><span style="font-weight: 400;">El procedimiento para hacer v&aacute;lida la
                promoci&oacute;n de </span><strong>&ldquo;ENV&Iacute;OS INTERNACIONALES&rdquo; </strong><span
                style="font-weight: 400;">es el siguiente:</span>
              <ol>
                <li><strong><span style="font-weight: 400;">Al momento de generar la compra dentro de la plataforma de
                      ecart.com
                      e introducir la direcci&oacute;n internacional de la entrega, se activar&aacute;
                      autom&aacute;ticamente la
                      tarifa especial de los $10.99 USD (diez d&oacute;lares y noventa y nueve centavos USD) en la orden
                      de
                      compra y podr&aacute; proceder con el pago.</span></strong></li>
              </ol>
            </li>

            <li style="font-weight: 400;"><strong>PAQUETER&Iacute;AS APLICABLES.</strong><span
                style="font-weight: 400;"> La
                paqueter&iacute;a aplicable para la promoci&oacute;n de la tarifa especial de los $10.99 USD (diez
                d&oacute;lares y noventa y nueve centavos USD) </span><strong>para env&iacute;os internacionales
                ser&aacute;
                &uacute;nicamente FEDEX</strong><span style="font-weight: 400;">.&nbsp;</span></li>
          </ol>

          <p><span style="font-weight: 400;">Al participar en la promoci&oacute;n </span><strong>&ldquo;ENV&Iacute;OS
              INTERNACIONALES&rdquo;</strong><span style="font-weight: 400;">, el Cliente afirma que ha le&iacute;do y
              comprendido los presentes T&eacute;rminos y Condiciones, aceptando la veracidad, legitimaci&oacute;n y
              lineamientos por los cuales se regir&aacute; la promoci&oacute;n de manera libre y consciente, habiendo de
              su
              parte revisado satisfactoriamente la informaci&oacute;n descrita anteriormente, la cual se considera
              adecuada
              y suficiente.</span></p>

        </div>
      </b-tab>


      <b-tab title="HOT PROMO">
        <p class="text-center f-16"><strong>&ldquo;HOT PROMO&rdquo;</strong></p>
        <p>&nbsp;</p>
        <p><span style="font-weight: 400;">Los siguientes t&eacute;rminos y condiciones regir&aacute;n la CAMPA&Ntilde;A
            de </span><strong>&ldquo;HOT PROMO&rdquo;</strong><span style="font-weight: 400;"> de ECART TECHNOLOGIES,
            S.A. DE C.V. (en adelante &ldquo;ECART&rdquo;) que ofrecer&aacute; a los usuarios que deseen utilizar la
            plataforma digital de&nbsp;</span><a href="http://www.ecart.com/"><span
              style="font-weight: 400;">www.ecart.com</span></a><span style="font-weight: 400;"> (en adelante
            &ldquo;Sitio Web de ECART&rdquo;)</span><span style="font-weight: 400;">.</span></p> <br>
        <p><strong>ECART</strong><span style="font-weight: 400;"> tiene su domicilio ubicado
            en&nbsp;</span><strong>Belisario Dom&iacute;nguez, n&uacute;mero 2470, piso 3, Colonia Obispado, Monterrey,
            Nuevo Le&oacute;n, C.P. 64060.</strong></p> <br>
        <p><span style="font-weight: 400;">AL PARTICIPAR EN ESTA CAMPA&Ntilde;A, DECLARAS ESTAR DE ACUERDO CON LOS
            T&Eacute;RMINOS Y CONDICIONES DE PROMOCIONES Y OFERTAS EN EL SITIO WEB DE ECART, DANDO TU ACEPTACI&Oacute;N
            TOTAL PARA TODO EFECTO LEGAL. DICHOS T&Eacute;RMINOS Y CONDICIONES RESULTAN DEFINITIVOS Y VINCULANTES PARA
            LOS USUARIOS/CONSUMIDORES FINALES EN LO QUE SE REFIERE AL USO DEL SITIO WEB DE ECART. Si no est&aacute;s
            conforme con estos t&eacute;rminos y condiciones, por favor no participes en la campa&ntilde;a &ldquo;HOT
            PROMO&rdquo; del Sitio Web de&nbsp;ECART. Si tienes alguna duda respecto a los t&eacute;rminos y
            condiciones, por favor escribe un correo electr&oacute;nico a&nbsp;</span><a
            href="mailto:clientes@envia.com"><span style="font-weight: 400;">support@ecart.com</span></a><span
            style="font-weight: 400;">.</span></p> <br>
        <p><strong>PRIMERO.</strong><span style="font-weight: 400;"> La campa&ntilde;a &ldquo;HOT PROMO&rdquo; tiene
            como &uacute;nico objetivo impulsar las ventas de tu tienda.</span></p> <br>
        <p><strong>SEGUNDO. </strong><span style="font-weight: 400;">Reconoces y aceptas que ECART no es responsable de
            la cantidad de descuento que el usuario desee a&ntilde;adir.</span></p> <br>
        <p><strong>TERCERO.</strong><span style="font-weight: 400;"> Reconoces y aceptas que ECART no es responsable de
            pagarte el monto total descontado de los descuentos a&ntilde;adidos por el usuario, por lo que deslindas
            totalmente a ECART de cualquier responsabilidad.</span></p> <br>
        <p><strong>CUARTO.</strong><span style="font-weight: 400;"> La campa&ntilde;a &ldquo;HOT PROMO&rdquo; es
            v&aacute;lida &uacute;nicamente para las &oacute;rdenes que sean realizadas a trav&eacute;s del Sitio Web de
            ECART.</span></p> <br>
        <p><strong>QUINTO.</strong><span style="font-weight: 400;"> La campa&ntilde;a ser&aacute; v&aacute;lida
            &uacute;nicamente (i) del d&iacute;a 23 de mayo del 2021 a las 12:01 a.m. al d&iacute;a 31 de mayo del 2021
            a las 11:59 p.m., o (ii) hasta agotar existencias, lo que suceda primero.</span></p> <br>
        <p><strong>SEXTO.</strong><span style="font-weight: 400;"> Los descuentos de los productos ofrecidos NO son
            intercambiables y NO pueden ser transferidos.&nbsp;</span></p> <br>
        <p><span style="font-weight: 400;">Al participar en la campa&ntilde;a&nbsp;</span><strong>&ldquo;HOT
            PROMO&rdquo;</strong><span style="font-weight: 400;">, afirmas que has le&iacute;do y comprendido los
            presentes T&eacute;rminos y Condiciones aceptando la veracidad, legitimaci&oacute;n y lineamientos por los
            cuales se regir&aacute; la campa&ntilde;a de manera libre y consciente, habiendo de tu parte revisado
            satisfactoriamente la informaci&oacute;n descrita anteriormente, la cual se considera adecuada y
            suficiente.</span></p> <br>
        <p>&nbsp;</p>
      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
  export default {
    data() {
      return {
        tabIndex: 0,
      }
    },
    beforeMount() {
      this.tabIndex = Number(this.$route.query.tab) || 0;
    }
  }
</script>